<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading" cancelText="取消" okText="保存" width="62.5%" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" layout="vertical">
      <a-form-item label="帮助标题">
        <a-input v-decorator="rules.helpName"/>
      </a-form-item>
      <a-form-item label="所属分类">
        <a-select placeholder="请选择" v-decorator="rules.categoryId">
          <a-select-option v-for="(item, index) of categorys" :key="index" :value="item.helper_category_id">{{ item.filter_name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="标签">
        <a-input v-decorator="rules.helpLabel "/>
      </a-form-item>
      <a-form-item label="排序">
        <a-input v-decorator="rules.helpSort "/>
      </a-form-item>
      <a-form-item label="帮助描述">
        <l-editor name="help_description" :data="editor.help_description"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'
import url from '@/utils/URL'
import createFilePath from '@/utils/tools'

export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },

  data() {
    return {
      labelCol: { span: 3 },
      confirmLoading: false,
      visible: false,
      ModalTitle: '新增帮助',
      categorys: [],

      form: this.$form.createForm(this),
      rules: {
        categoryId: [ 'help_category_id',
          {rules: [{ required: true, message: '请选择分类!' }]}
        ],
        helpName: [ 'help_name',
          {rules: [{ required: true, message: '请输入帮助标题!' }]}
        ],
        helpLabel: [ 'help_label',
          {rules: []}
        ],
        helpSort: [ 'help_sort',
          {rules: []}
        ],
        helpDescription: [ 'help_description',
          {rules: [{ required: true, message: '请输入帮助描述!' }]}
        ]
      },
      uploadUrl: url.uploadQiNiu, 
      uploadParams: {},
      fileList: [],
      help_description: '',
      editor: {},
    }
  },

  async created() {
    this.visible = true
    this.getCateList()
    await this.$nextTick()
    let { item } = this
    if (item) {
      this.ModalTitle = '编辑'
      let res = await this.$store.dispatch('xtHelpViewAction', { data: { help_id: item.id } })
      if (res) {
        item = res.data
        this.form.setFieldsValue({
          help_category_id: item.help_category_id,
          help_name: item.help_name,
          help_label: item.help_label,
          help_sort: item.help_sort,
          help_description: item.help_description
        })
        this.editor.help_description = item['help_description']
      }
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    changeEditor(key, value) {
      this.editor[key] = value
    },
    async getCateList() {
      let res = await this.$store.dispatch('searchHelperTypeAction', { data: {} })
      if (res.data) {
        this.categorys = res.data
      }
    },
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.help_id = item.id
          } 
          params.help_description = this.editor.help_description
          let res = await this.$store.dispatch(item ? 'xtHelpEditAction' : 'xtHelpAddAction', { data: params })
          if(res) {
            form.resetFields()
            this.parent.submitModal()
          }
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideModal()
    },
  }
}
</script>
