<template>
  <div class="c-side">
    <div class="buttons">
      <a-form layout='inline'>
        <a-form-item>
          <a-button type="primary" icon="plus" @click="showEditModal($event)">添加分类</a-button>
        </a-form-item>
      </a-form>
    </div>
    <div class="list">
      <div class="item" :class="{ 'active': !cateId }" @click="clickCategory({helper_category_id: '', name: '全部'})">
        <div class="name">全部</div>
      </div>
      <div class="item" :class="{ 'active': cateId === item.helper_category_id }" 
        v-for="(item, index) of folders" :key="index" @click="clickCategory(item)">
        <div class="name">{{ item.filter_name }}</div>
        <div class="btns">
          <div class="btn btn-parmary" @click="showEditModal($event, item)">编辑</div>
          <div class="btn btn-delete" @click="deleteCategory($event, item)">删除</div>
        </div>
      </div>
    </div>
    <editModal v-if="visible" :item="modalData"/>
  </div>
</template>

<script>
import editModal from './editModal'

export default {
  name: 'Category',
  inject: [ 'parent' ],
  provide() {
    return {
      parent: this
    }
  },
  components: {
    editModal
  },

  data() {
    return {
      cateId: '',
      folders: [],
      searchParams: { q: '' },

      visible: false,
      modalData: null
    }
  },

  async created() {
    await this.getList()
    this.parent.changeCategory({helper_category_id: '', filter_name: '全部'})
  },

  methods: {
    async getList() {
      let res = await this.$store.dispatch('searchHelperTypeAction', { data: this.searchParams })
      if (res.data) {
        this.folders = res.data
      }
    },
    clickCategory(item) {
      this.cateId = item.helper_category_id
      this.parent.changeCategory(item)
    },
    showEditModal(e, item) {
      e.stopPropagation()
      this.modalData = item
      this.visible = true
    },
    hideModal() {
      this.visible = false
    },
    submitModal() {
      this.visible = false
      this.getList()
    },
    deleteCategory(e, item) {
      e.stopPropagation()
      let that = this
      this.$confirm({
        title: `确定要删除资料分类"${item.filter_name}"?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('xtHelpCateDeleteAction', { data: { category_id: item.helper_category_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    }
  }
}
</script>
