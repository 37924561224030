<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item label="分类名称" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="rules.categoryName"/>
      </a-form-item>
      <a-form-item label="分类排序" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="rules.categorySort"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      confirmLoading: false,
      visible: false,
      ModalTitle: '新增分类',

      form: this.$form.createForm(this),
      rules: {
        categoryName: [ 'category_name',
          {rules: [{ required: true, message: '请输入分类名称!' }]}
        ],
        categorySort: [ 'category_sort',
          {rules: [{ required: true, message: '请输入排序!' }]}
        ]
      },
    }
  },

  async created() {
    this.visible = true
    await this.$nextTick()
    const { item } = this
    if (item) {
      this.ModalTitle = '编辑分类'
      this.form.setFieldsValue({
        category_name: item.filter_name,
        category_sort: 1
      })
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.category_id = item.helper_category_id
          }
          let res = await this.$store.dispatch(item ? 'xtHelpCateEditAction' : 'xtHelpCateAddAction', { data: params })
          if(res) {
            form.resetFields()
            this.parent.submitModal()
          }
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideModal()
    }
  }
}
</script>
