<template>
<div>
  <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>企业管理</a-breadcrumb-item>
      <a-breadcrumb-item>帮助中心</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-box">
  <div class="common zlgx">
    <category />
    <div class="c-main">
      <div class="zl-list">
        <div class="title flts-title">
          <h3>{{ currentCategory.filter_name }}</h3>
          <a-button type="primary" icon="plus" @click="showEditModal()">添加帮助</a-button>
        </div>
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <div class="help-list" style="height:650px;overflow:auto;">
          <a-list itemLayout="horizontal" :dataSource="list">
            <a-list-item slot="renderItem" slot-scope="item">
              <!-- <a slot="actions">删除</a> -->
              <a slot="actions" @click="showEditModal(item)">修改</a>
              <a slot="actions">查看</a>

              <a-list-item-meta @click="showDetailModal(item)">
                <a slot="title">【{{ item.help_label }}】{{ item.help_name }}</a>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </div>

    <editModal v-if="visible" :item="modalData"/>

    <a-modal v-if="detail_visible" v-model="detail_visible" :title="modalData.help_name" :item="modalData" @ok="handleDetailOk">
      <div v-html="modalData.help_description">
      </div>
    </a-modal>
  </div>
</div>
</div>
</template>

<script>
import C_ITEMS from '@/utils/items'
import category from './category/index'
import editModal from './editModal'

export default {
  name: 'zlgx',
  provide() {
    return {
      parent: this
    }
  },
  components: {
    category,
    editModal
  },

  data() {
    return {
      loading: false,
      detail_visible:false,
      C_ITEMS,
      currentCategory: { },
      list: [],
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },

      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {
        },
        "sort": ''
      },

      visible: false,
      modalData: null,
    }
  },

  created() {
    
  },

  watch: {
    currentCategory() {
      this.getList()
    }
  },

  methods: {
    changeCategory(item) {
      this.currentCategory = item
    },
    async getList() {
      this.loading = true
      const { searchParams } = this
      const { helper_category_id } = this.currentCategory
      let res = await this.$store.dispatch('xtHelpAction', { params: { category_id: helper_category_id }, data: searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },

    changePage(page, pageSize) {
      this.searchParams.page = page
      this.getList()
    },
    onShowSizeChange(current, size) {
      this.searchParams['per-page'] = size
      this.getList()
    },
    showDetailModal(item) {
      this.modalData = item;
      this.detail_visible = true;
    },
    handleDetailOk(e) {
      console.log(e);
      this.detail_visible = false;
    },
    showEditModal(item) {
      this.modalData = item
      this.visible = true
    },
    hideModal() {
      this.visible = false
    },
    submitModal() {
      this.visible = false
      this.getList()
    },

    // deleteZl(e, item) {
    //   e.stopPropagation()
    //   let that = this
    //   this.$confirm({
    //     title: `确定要删除帮助"${item.file_name}"?`,
    //     okText: '确定',
    //     okType: 'danger',
    //     cancelText: '取消',
    //     onOk() {
    //       return new Promise(async (resolve, reject) => {
    //         let res = await that.$store.dispatch('zlShareDeleteAction', { id: item.id })
    //         if (res) {
    //           that.getList()
    //           resolve(res)
    //         }
    //       }).catch(error => console.log(error))
    //     }
    //   })
    // },
  }
}
</script>
